import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import useIsMobileScreen from "../../core/hooks/use-is-mobile-screen.hooks";
import { appRoutesObj } from "../../app.paths";
import { LanguagesEnum } from "../../core/enums/languages.enum";
import HeaderMobile from "./header.mobile";
import HeaderDesktop from "./header.desktop";
import { useEffect } from "react";
import useModeStore from "../../core/store/mode.store";

export default function Header() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useIsMobileScreen();
  const { setMode, mode } = useModeStore();

  const handleLanguageChange = () => {
    if (i18n.language === LanguagesEnum.AR) {
      i18n.changeLanguage(LanguagesEnum.EN);
      localStorage.setItem("language", LanguagesEnum.EN);
    } else {
      i18n.changeLanguage(LanguagesEnum.AR);
      localStorage.setItem("language", LanguagesEnum.AR);
    }
  };

  const handleChangeMode = () => {
    if (localStorage.getItem("mode")) {
      if (localStorage.getItem("mode") === "dark") {
        localStorage.setItem("mode", "light");
        setMode("light");
      } else {
        localStorage.setItem("mode", "dark");
        setMode("dark");
      }
    } else {
      localStorage.setItem("mode", "dark");
      setMode("dark");
    }
  };

  const handleLogoClick = () => {
    navigate(appRoutesObj.getBaseUrl());
  };

  useEffect(() => {
    if (location.hash.includes("#individual")) {
      if (location.pathname !== appRoutesObj.getBaseUrl()) {
        navigate(appRoutesObj.getBaseUrl());
      }

      window.scrollTo({
        top: document.getElementById("individual")?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.hash, location.pathname, navigate]);

  useEffect(() => {
    if (location.hash.includes("#company")) {
      if (location.pathname !== appRoutesObj.getBaseUrl()) {
        navigate(appRoutesObj.getBaseUrl());
      }

      window.scrollTo({
        top: document.getElementById("company")?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.hash, location.pathname, navigate]);

  return (
    <nav className="inset-x-0 shadow-section fixed dark:bg-opacity-30 bg-white top-0 right-0 left-0 z-40 flex h-16 xl:h-auto xl:px-3 gap-16">
      <div className="xl:max-w-7xl sm:max-w-4xl w-full max-w-xs mx-auto">
        {isMobile ? (
          <HeaderMobile
            handleLanguageChange={handleLanguageChange}
            handleLogoClick={handleLogoClick}
            handleChangeMode={handleChangeMode}
            isDarkMode={mode === "dark"}
          />
        ) : (
          <HeaderDesktop
            handleLanguageChange={handleLanguageChange}
            handleLogoClick={handleLogoClick}
            handleChangeMode={handleChangeMode}
            isDarkMode={mode === "dark"}
          />
        )}
      </div>
    </nav>
  );
}
