import { t } from "i18next";
import { appRoutesObj } from "../../app.paths";

export const getHeaderLinks = (pathname: string) => {
  return [
    {
      to: appRoutesObj.getAboutUsUrl(),
      name: t("components.Header.us"),
      isActive: pathname === appRoutesObj.getAboutUsUrl(),
    },
    {
      to: appRoutesObj.getBaseUrl() + "#individual",
      name: t("components.Header.individual"),
      isActive: pathname === appRoutesObj.getBaseUrl() + "#individual",
    },
    {
      to: appRoutesObj.getBaseUrl() + "#company",
      name: t("components.Header.company"),
      isActive: pathname === appRoutesObj.getBaseUrl() + "#company",
    },
    {
      to: appRoutesObj.getContactUrl(),
      name: t("components.Header.contact"),
      isActive: pathname === appRoutesObj.getContactUrl(),
    },
  ];
};
