import logo from "../../assets/icons/logo.svg";

import { useTranslation } from "react-i18next";
import { MdLocationPin } from "@react-icons/all-files/md/MdLocationPin";
import { MdMail } from "@react-icons/all-files/md/MdMail";
import { AiFillPhone } from "@react-icons/all-files/ai/AiFillPhone";
import appStore from "../../assets/icons/app-btn-white.svg";
import playStore from "../../assets/icons/play-btn-white.svg";
import appStoreTrans from "../../assets/icons/app-btn-white-trans.svg";
import playStoreTrans from "../../assets/icons/play-btn-white-trans.svg";
import useModeStore from "../../core/store/mode.store";
import { appRoutesObj } from "../../app.paths";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Footer() {
  const { t } = useTranslation();
  const { mode } = useModeStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash.includes("#individual")) {
      if (location.pathname !== appRoutesObj.getBaseUrl()) {
        navigate(appRoutesObj.getBaseUrl());
      }

      window.scrollTo({
        top: document.getElementById("individual")?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.hash, location.pathname, navigate]);

  useEffect(() => {
    if (location.hash.includes("#company")) {
      if (location.pathname !== appRoutesObj.getBaseUrl()) {
        navigate(appRoutesObj.getBaseUrl());
      }

      window.scrollTo({
        top: document.getElementById("company")?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.hash, location.pathname, navigate]);

  return (
    <>
      <div className="shadow-section w-full">
        <div className="xl:max-w-7xl sm:max-w-2xl mx-auto w-full max-w-xs py-16">
          <div className="grid xl:grid-cols-4 grid-cols-2 items-start justify-between gap-10">
            <div>
              <img
                src={logo}
                alt={t("components.Footer.go")!}
                className="w-28 h-auto"
              />
            </div>
            <div className="order-3 sm:order-2">
              <p className="text-primary dark:text-white text-sm sm:text-2xl mb-6">
                {t("components.Footer.go")}
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col gap-4">
                  <Link
                    to={appRoutesObj.getAboutUsUrl()}
                    className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light"
                  >
                    {t("components.Footer.us")}
                  </Link>
                  <Link
                    to={appRoutesObj.getBaseUrl() + "#individual"}
                    className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light"
                  >
                    {t("components.Footer.individual")}
                  </Link>
                  <Link
                    to={appRoutesObj.getBaseUrl() + "#company"}
                    className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light"
                  >
                    {t("components.Footer.company")}
                  </Link>
                </div>
                <div className="flex flex-col gap-4">
                  <a
                    href="/"
                    className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light"
                  >
                    {t("components.Footer.privacy")}
                  </a>
                  <a
                    href="/"
                    className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light"
                  >
                    {t("components.Footer.terms")}
                  </a>
                </div>
              </div>
            </div>
            <div className="order-last sm:order-3">
              <p className="text-primary dark:text-white text-sm sm:text-2xl mb-6">
                {t("components.Footer.contact")}
              </p>
              <div className="flex flex-col gap-4">
                <a
                  href="tel:00967530967966"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light flex items-center gap-3"
                >
                  <div>
                    <AiFillPhone className="text-primary dark:text-white" />
                  </div>
                  0530967966
                </a>
                <a
                  href="mailto:almarriH@fa-wa.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light flex items-center gap-3"
                >
                  <div>
                    <MdMail className="text-primary dark:text-white" />
                  </div>
                  almarriH@fa-wa.com
                </a>
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#99989B] dark:text-[#E0E0E0] text-xs sm:text-lg font-light flex items-center gap-3"
                >
                  <div>
                    <MdLocationPin className="text-primary dark:text-white" />
                  </div>
                  {t("components.Footer.location")}
                </a>
              </div>
            </div>
            <div className="order-2 sm:order-last">
              <a
                href="https://apps.apple.com/sa/app/fawa/id6446460146?l=ar"
                className="block mb-4"
              >
                <img
                  src={mode === "dark" ? appStoreTrans : appStore}
                  alt={t("components.Footer.app")!}
                  className="h-10 sm:h-20 w-auto"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.fawa.sa"
                className="block"
              >
                <img
                  src={mode === "dark" ? playStoreTrans : playStore}
                  alt={t("components.Footer.app")!}
                  className="h-10 sm:h-20 w-auto"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
