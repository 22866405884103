import { useEffect, useState } from "react";
import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import { HiX } from "@react-icons/all-files/hi/HiX";
import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/icons/logo.svg";
import { getHeaderLinks } from "./header.data";
import { HiOutlineMoon } from "@react-icons/all-files/hi/HiOutlineMoon";
import { HiOutlineSun } from "@react-icons/all-files/hi/HiOutlineSun";
import { useTranslation } from "react-i18next";

interface HeaderMobileProps {
  handleLogoClick: () => void;
  handleLanguageChange: () => void;
  handleChangeMode: () => void;
  isDarkMode: boolean;
}

export default function HeaderMobile({
  handleLogoClick,
  handleChangeMode,
  handleLanguageChange,
  isDarkMode,
}: HeaderMobileProps) {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isContentOptionsShown, setIsContentOptionsShown] =
    useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isCollapsed) {
      // to disable  scrolling on the page
      window.document.body.style.position = "fixed";
      window.document.body.style.width = "100%";
    } else {
      window.document.body.style.position = "unset";
    }
  }, [isCollapsed]);

  return (
    <div className="flex w-full justify-between">
      <div
        className={`${
          isCollapsed ? "h-0 py-0" : "py-4 h-[280px]"
        } bg-white dark:bg-header-dark absolute top-16 z-[9999] p-5 shadow-xl w-60 rounded-b-[30px] backdrop-blur-xl mx-auto inset-x-0 transition-all duration-150 overflow-hidden`}
      >
        <div className="flex w-full flex-col gap-6 justify-center items-center text-center">
          {getHeaderLinks(location.pathname).map((link, index) => (
            <div
              className="text-sm text-[#8993A4] dark:text-white w-fit"
              key={`nav-${index}`}
            >
              <Link onClick={() => setIsCollapsed(true)} to={link.to}>
                <div className={`${link.isActive ? "text-accent" : ""}`}>
                  {link.name}
                </div>
              </Link>
            </div>
          ))}
          <button
            title="language"
            onClick={handleLanguageChange}
            className="text-sm text-[#8993A4] dark:text-white"
          >
            {t("components.Header.lang")}
          </button>
          <button
            title="mode"
            onClick={handleChangeMode}
            className="dark:text-white"
          >
            {isDarkMode ? (
              <HiOutlineSun />
            ) : (
              <HiOutlineMoon className="text-secondary" />
            )}
          </button>
        </div>
      </div>
      <div className="mt-2 z-50">
        <img
          src={logo}
          alt=""
          className="h-10 w-auto"
          onClick={handleLogoClick}
        />
      </div>

      <div className="z-50">
        <button
          title="menu"
          type="button"
          className="mt-4 text-[#8993A4] dark:text-white"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
            setIsContentOptionsShown(!isContentOptionsShown);
          }}
        >
          {!isCollapsed ? <HiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>
    </div>
  );
}
