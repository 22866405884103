import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { LanguagesEnum } from "../../core/enums/languages.enum";
import logo from "../../assets/icons/logo.svg";
import { getHeaderLinks } from "./header.data";
import { HiOutlineMoon } from "@react-icons/all-files/hi/HiOutlineMoon";
import { HiOutlineSun } from "@react-icons/all-files/hi/HiOutlineSun";

interface HeaderDesktopProps {
  handleLogoClick: () => void;
  handleLanguageChange: () => void;
  handleChangeMode: () => void;
  isDarkMode: boolean;
}

export default function HeaderDesktop({
  handleLogoClick,
  handleChangeMode,
  handleLanguageChange,
  isDarkMode,
}: HeaderDesktopProps) {
  const { i18n, t } = useTranslation();
  const location = useLocation();

  return (
    <div className="mx-auto flex justify-center w-full sm:max-w-7xl relative">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <div
            className={`h-full flex items-center justify-center ${
              i18n.language === LanguagesEnum.EN ? "mr-5" : "ml-5"
            }`}
          >
            <img
              src={logo}
              alt=""
              className="h-20 w-auto cursor-pointer"
              onClick={handleLogoClick}
            />
          </div>

          {getHeaderLinks(location.pathname).map((link, index) => (
            <Link
              key={`link-${index}`}
              to={link.to}
              className="flex group flex-col"
            >
              <div
                className={`mx-5 group-hover:text-accent font-light text-lg transition-all duration-300 ${
                  link.isActive
                    ? "text-accent border-b border-b-accent"
                    : "text-[#8FA0AD] dark:text-white"
                }`}
              >
                {link.name}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <button title="mode" onClick={handleChangeMode}>
          {isDarkMode ? <HiOutlineSun className="text-white" /> : <HiOutlineMoon className="text-accent" />}
        </button>
        <button
          onClick={handleLanguageChange}
          className="text-accent dark:text-white font-light text-xl"
        >
          {t("components.Header.lang")}
        </button>
      </div>
    </div>
  );
}
